<template>
  <div class="about">
    <div class="site-content">
      <el-row :gutter="20">
        <el-col :span="17">
          <div class="content-warp">
            <div class="center_left_article" style="margin-top: 0px">
              <h3>关于本站</h3>

              <div class="center_left_about">
                <p style="text-indent: 20px">
                  和平常人一样，我的学历经过了幼儿园，小学，初中，高中和大学...
                  小学，初中，高中都没有什么说的，都是接受天朝的教育模式一步步走来。中间没有犯过大的过错，也没有得到过大的奖励(小学参加丹棱县小学生运动会获得100米冠军算不算？哈哈)，比较平平淡淡的度过了这几个非常重要的学习时刻。在那个时期我非常喜欢打篮球，喜欢看篮球比赛（NBA），
                  几乎每个周末的比赛都不会错过。放寒假的时候则是牢牢记住比赛日程，每一场都要看。
                  上大学以后感觉一切都变了，以前的爱好没有了，以前的那份天真也没有。大一的时候天天在寝室打游戏，那个时候疯狂的迷恋上了魔兽世界，觉得这个游戏太完了，胜过一切。因此自己的学习成绩很不好，大一挂科非常多。大二后，
                  我开始对自己的未来有了想看，不能再这么荒废下去了...
                  我是学GIS专业的，我们专业大致分为2个部分：一个是GIS应用另一个是GIS二次开发。因为GIS二次开发是要学习相关开发语言的，也就是计算机编程。此后，我也疯狂的喜欢上了计算机编程，总觉的能给自己带来很多快乐。于是，
                  花了很多经历去学习编程语言。我刚开始真正学习的计算机编程语言是C#。经过不断的努力，自己对这门语言有了基础的掌握，于是开始动手为自己写一些程序。每当自己克服困难完成了一个小程序后，自己总店感到无比的快乐与巨大的
                  成就感，虽然现在看起来当初那些程序很简单，写的也不好，但这总归是一个好的开始。
                  开始工作后，我涉入了计算机编程的很多领域。Java,Flash,Html等等。不断的丰富自己的阅历和提高自己的能力。这个网站也是自己在不断的学习中建立起来的，开始尝试Html5和CSS3技术，新技术就是要敢于尝试。
                  感谢何咩咩对我支持和鼓励，她不断的为我提供建议和想法，也让我成长了不少。同时，本站内的很多图标都是何MiMie为本站量身打造的。赞一个！
                </p>

                <span class="webinfo">网站信息</span>

                <section-title
                  ><span> 网站名称：pengyaou个人网站</span></section-title
                >
                <section-title
                  ><span> 网站备案：赣ICP备2020010445号</span></section-title
                >

                <section-title
                  ><span> 上线时间：2014年3月12日</span></section-title
                >
                <section-title
                  ><span> 前端技术:html vue element</span></section-title
                >

                <section-title
                  ><span>
                    后台技术：Net5 sqlsugarcore furion</span
                  ></section-title
                >

                <section-title
                  ><span>
                    存储技术：SQL Server redis mogodb
                  </span></section-title
                >

                <section-title
                  ><span> 云服务器:腾讯云contos7 </span></section-title
                >

                <section-title><span> 部署方式:docker </span></section-title>

                <section-title
                  ><span> 网站类型：个人网站 个人博客 </span></section-title
                >

                <section-title
                  ><span>
                    网站说明：此网站作为学习交流网站，而非商业用途的网站，网站信息来自作者原创和第三方网站。任何组织或个人不得利用本网站进行违法或商业行为，违者必追究其法律责任。如果本站内容侵犯了您的专利或者权益，请联系站长，我们将立刻处理。
                  </span></section-title
                >
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="7"
          ><div class="content-warp">
            <div class="center_left_article" style="margin-top: 0px">
              <BlogList :BlogarticleDto="BlogarticleDto"></BlogList>

              <Userinfo></Userinfo>

              <Homemsg :MessageModelAsync="MessageModelAsync"></Homemsg>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import {
  AddBoardModel,
  GetBlogarticleDtoAsync,
  MessageModelAsync,
} from "../api/Resources";
import sectionTitle from "@/components/section-title";
export default {
  name: "About",
  components: {
    sectionTitle,
    Userinfo: () => import("@/components/Userinfo"),
    Homemsg: () => import("@/components/Homemsg"),
    BlogList: () => import("@/components/BlogList"),
  },
  data() {
    return {
      BlogAboutModel: "",
      loading: false,
      BlogarticleDto: [],
      MessageModelAsync: [],
    };
  },

  methods: {
    async GetBlogarticleDtoAsync() {
      var res = await GetBlogarticleDtoAsync();
      this.BlogarticleDto = res.rspdata || [];
    },
    async GetMessageModelAsync() {
      var res = await MessageModelAsync();
      this.MessageModelAsync = res.rspdata || [];
    },
  },
  mounted() {
    this.GetBlogarticleDtoAsync();
    this.GetMessageModelAsync();
  },
};
</script>
<style scoped lang="less">
.site-content {
  width: 100%;
  .content-warp {
    margin-top: 80px;
    .center_left_article {
      border: 1px solid #f7acbc;
      margin-top: 30px;
      width: 100%;
      position: customer;
      background-color: #fff;
      border-radius: 10px;
      overflow: hidden;

      h3 {
        width: 100%;
        height: 45px;
        line-height: 45px;
        padding-left: 10px;
        background-color: #f7acbc;
        color: #fff;
      }
      .center_left_about {
        width: 100%;
        padding: 1%;

        p,span
         {
          line-height: 30px;
        }
        // .spanbuuton {
        //   margin-bottom: 10px;
        //   border-bottom: 1px solid red;
        // }

        .webinfo {
          display: inline-block;
          margin-top: 20px;
          margin-bottom: 20px;
          padding: 5px 15px 5px 15px;
          background-color: #f7acbc;
          border-radius: 0px 2em 2em 0px;
          color: #fff;
          font-size: 14px;
        }
      }
    }
  }
}

/*******/
@media (max-width: 1100px) {
  .content-warp {
    margin-top: 0;
  }
}
</style>
